import React from "react";
import { BrowserRouter as Router, Routes, Route, Outlet } from "react-router-dom";
import MenuItemForm from "./pages/MenuItemForm";
import OrderManagement from "./pages/OrderManagement";
import MainLayout from "./assets/MainLayout";
import OngoingOrders from "./pages/OngoingOrders";
import Transactions from "./pages/Transactions";
import Dashboard from "./pages/Dashboard";
import POSReceipt from "./components/POSReceipt";
import { AuthProvider } from "./context/AuthContext";
import LoginForm from "./pages/LoginForm";
import ProtectedRoutes from "./components/ProtectedRoutes";

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          {/* Public Route */}
          <Route path="/login" element={<LoginForm />} />

          {/* Protected Routes */}
          <Route element={<ProtectedRoutes />}>
            {/* Nested routes under MainLayout */}
            <Route path="/" element={<MainLayout />}>
              <Route path="menu" element={<MenuItemForm />} />
              <Route path="ongoing-orders" element={<OngoingOrders />} />
              <Route path="order-management" element={<OrderManagement />} />
              <Route path="transactions" element={<Transactions />} />
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="pos-receipt" element={<POSReceipt />} />
            </Route>
          </Route>
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;
