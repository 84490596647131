import React, { useState, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Button,
  Dropdown,
  Menu,
  Collapse,
  Typography,
  Carousel,
  Divider,
  InputNumber,
  message,
  Modal,
  Input,
  Select,
} from "antd";
import { CaretRightOutlined, CaretDownOutlined, CloseCircleOutlined, CaretLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Timer from "../assets/SideBarImages/Timer.png";
import Dashboard from "../assets/SideBarImages/DashboardBlack.png";
import Pause from "../assets/SideBarImages/Pause.png";
import Rights from "../assets/SideBarImages/Rights.png";
import axios from "axios";
import ReactToPrint from "react-to-print";
import Kot from "../assets/Kot";
import Food from "../assets/SideBarImages/foodplaceholder.png"; // Ensure the correct path to Kot component

const { Panel } = Collapse;
const { Text } = Typography;

const OrderManagement = () => {
  const [menuItems, setMenuItems] = useState([]);
  const [displayedItems, setDisplayedItems] = useState([]); // This will determine what to display in the cards
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedTableId, setSelectedTableId] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [hoveredItemId, setHoveredItemId] = useState(null);
  const [orderAmount, setOrderAmount] = useState(0);
  const [categories, setCategories] = useState([]);
  const [tables, setTables] = useState([]);
  const [kotItems, setKotItems] = useState([]);
  const [printKotModalVisible, setPrintKotModalVisible] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isDropdownHovered, setIsDropdownHovered] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const navigate = useNavigate();

  const pageStyle = `
    @page {
      size: 80mm 297mm;
      margin: 20mm;
    };
  
    @media all {
      .pagebreak {
        display: none;
      };
    }
  
    @media print {
      .pagebreak {
        display: block;
        page-break-before: always;
      };
    }
  `;

  useEffect(() => {
    fetchMenuItems();
    fetchTables();
  }, []);

  useEffect(() => {
    const total = selectedItems.reduce((sum, item) => {
      const itemTotal = item.price * item.quantity;
      const discountAmount =
        item.discountType === "percentage"
          ? item.price * (item.discount / 100) * item.quantity // Apply discount for each unit
          : item.discount * item.quantity; // Apply flat discount for each unit
      return sum + (itemTotal - discountAmount);
    }, 0);
    setOrderAmount(total);
  }, [selectedItems]);

  const handleButtonClick = () => {
    navigate("/ongoing-orders");
  };

  const fetchMenuItems = async () => {
    try {
      const response = await axios.get("https://bkpos.debuxglobal.com/api/menus", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      setMenuItems(response.data);
      setDisplayedItems(response.data); // Initially display all items
      const categories = ["All", ...new Set(response.data.map((item) => item.category_name))];
      setCategories(categories);
    } catch (error) {
      console.error("Error fetching menu items:", error);
    }
  };

  const fetchTables = async () => {
    try {
      const response = await axios.get("https://bkpos.debuxglobal.com/api/tables", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      setTables(response.data.availableTables); // Assuming the API returns tables in a structure with availableTables
    } catch (error) {
      console.error("Error fetching tables:", error);
    }
  };

  const handleTableSelect = (tableId) => {
    setSelectedTableId(tableId);
  };

  const handleAddToOrder = (itemId) => {
    if (!selectedTableId) {
      message.error("Please select a table before adding items to the order.");
      return;
    }

    const item = menuItems.find((item) => item.id === itemId);
    const existingItemIndex = selectedItems.findIndex((selectedItem) => selectedItem.id === itemId);

    if (existingItemIndex !== -1) {
      // If the item already exists in the order, increment its quantity by 1
      const updatedItems = [...selectedItems];
      updatedItems[existingItemIndex].quantity += 1;
      setSelectedItems(updatedItems);
    } else {
      // If the item is being added for the first time, set its quantity to 1
      setSelectedItems([...selectedItems, { ...item, quantity: 1, discount: 0, discountType: "percentage" }]);
    }
  };

  // Update quantity in the collapse section
  const handleQuantityChange = (itemId, quantity) => {
    const updatedItems = selectedItems.map((item) => {
      if (item.id === itemId) {
        return { ...item, quantity: quantity || 1 }; // Ensure quantity is at least 1
      }
      return item;
    });
    setSelectedItems(updatedItems);
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    if (query === "") {
      setDisplayedItems(menuItems); // Show all items if the search is cleared
    } else {
      setDisplayedItems(menuItems.filter((item) => item.name.toLowerCase().includes(query.toLowerCase())));
    }
  };

  const handleRemoveFromOrder = (itemId) => {
    const updatedItems = selectedItems.filter((item) => item.id !== itemId);
    setSelectedItems(updatedItems);
  };

  const handleDiscountChange = (itemId, discount, discountType) => {
    const updatedItems = selectedItems.map((item) => {
      if (item.id === itemId) {
        return { ...item, discount, discountType };
      }
      return item;
    });
    setSelectedItems(updatedItems);
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    if (category === "All") {
      setDisplayedItems(menuItems); // Show all items if "all" is selected
    } else {
      setDisplayedItems(menuItems.filter((item) => item.category_name === category));
    }
  };

  const handleConfirmOrder = async () => {
    if (!selectedTableId) {
      message.error("Please select a table before confirming the order.");
      return;
    }

    if (selectedItems.length === 0) {
      message.error("Please add items to the order before confirming.");
      return;
    }

    try {
      const response = await axios.post(
        "https://bkpos.debuxglobal.com/api/orders",
        {
          table_id: selectedTableId,
          menu_items: selectedItems.map((item) => {
            const itemTotal = item.price * item.quantity;

            // Calculate discount for percentage or flat
            const discountAmount =
              item.discountType === "percentage"
                ? item.discount > 100
                  ? 0 // If discount is over 100%, apply 0 discount
                  : item.price * (item.discount / 100) * item.quantity // Otherwise, apply the percentage discount
                : item.discount * item.quantity; // Fixed discount applied per unit

            const totalAfterDiscount = itemTotal - discountAmount;

            return {
              id: item.id,
              quantity: item.quantity,
              discount: discountAmount.toFixed(2), // Ensure consistency in decimal places
              price: item.price,
              total_after_discount: totalAfterDiscount.toFixed(2),
            };
          }),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            // Add any other headers here if needed
          },
        }
      );
      console.log("Order placed:", response.data);
      message.success("Order successfully placed!");
      setOrderId(response.data.order_id); // Save order ID for KOT printing
      setSelectedItems([]); // Clear selected items after placing the order
      setSelectedTableId(null);
      setOrderAmount(0);
      fetchTables();
    } catch (error) {
      console.error("Error placing order:", error);
      message.error("Failed to place the order. Please try again.");
    }
  };

  const handlePrintKot = () => {
    if (selectedItems.length === 0) {
      message.error("No items available for this order.");
      return;
    }
    setKotItems(selectedItems);
    setPrintKotModalVisible(true);
  };

  const handleNoteChange = (itemId, note) => {
    const updatedItems = selectedItems.map((item) => {
      if (item.id === itemId) {
        return { ...item, note };
      }
      return item;
    });
    setSelectedItems(updatedItems);
  };

  const tableMenu = (
    <Menu>
      {tables.map((table) => (
        <Menu.Item
          style={{ fontFamily: "Nunito", textAlign: "center" }}
          key={table.table_id}
          onClick={() => handleTableSelect(table.table_id)}
        >
          Table {table.table_number}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div style={{ backgroundColor: "#F4F4F4" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "5px",
          marginTop: "-15px",
        }}
      >
        <Text
          style={{
            fontSize: "36px",
            fontWeight: "bold",
            color: "#000000",
            fontFamily: "Nunito",
          }}
        >
          Your Orders
        </Text>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button
            style={{
              fontFamily: "Nunito",
              fontWeight: "700",
              width: "180px",
              height: "50px",
              marginRight: "10px",
              border: isHovered ? "1px solid #F2890D" : "1px solid transparent",
              color: isHovered ? "#F2890D" : "inherit",
              padding: "10px",
              cursor: "pointer",
              transition: "border-color 0.3s ease", // Adding smooth transition
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={handleButtonClick}
          >
            <img src={Timer} width={16} height={16} alt="Dashboard Icon" style={{ marginRight: "8px" }} />
            On Going Orders
          </Button>
          <Dropdown overlay={tableMenu} placement="bottomLeft" style={{ fontFamily: "Nunito" }}>
            <Button
              style={{
                fontFamily: "Nunito",
                fontWeight: "700",
                width: "150px",
                height: "50px",
                border: isDropdownHovered ? "1px solid #F2890D" : "1px solid transparent",
                color: isDropdownHovered ? "#F2890D" : "inherit",
                transition: "border-color 0.3s ease", // Adding smooth transition
              }}
              onMouseEnter={() => setIsDropdownHovered(true)}
              onMouseLeave={() => setIsDropdownHovered(false)}
            >
              <img src={Dashboard} width={16} height={16} alt="Dashboard Icon" style={{ marginRight: "8px" }} />
              Select Table {selectedTableId ? `(${selectedTableId})` : ""}
            </Button>
          </Dropdown>
        </div>
      </div>
      <Row gutter={16}>
        <Col span={16}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "16px",
              height: "70px",
              backgroundColor: "#FFF",
              padding: "10px",
              borderRadius: "10px",
            }}
          >
            <div style={{ flex: 1, marginLeft: "8px", marginRight: "8px" }}>
              <Carousel
                className="carousel"
                slidesToShow={3}
                arrows // Allow dragging to navigate
                style={{ width: "1020px" }}
                dots={false}
              >
                {categories.map((category) => {
                  const isActive = selectedCategory === category;
                  return (
                    <div
                      key={category}
                      style={{
                        padding: "0 10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        type="default"
                        onClick={() => handleCategorySelect(category)}
                        style={{
                          width: "100%",
                          whiteSpace: "nowrap",
                          fontFamily: "Nunito",
                          fontWeight: "700",
                          border: isActive ? "2px solid #F2890C" : "2px solid transparent",
                          backgroundColor: isActive ? "#F2890C1A" : "transparent",
                          color: isActive ? "#F2890C" : "#000000",
                          padding: "10px 20px", // Increase padding for better spacing
                        }}
                      >
                        {category}
                      </Button>
                    </div>
                  );
                })}
              </Carousel>
            </div>
          </div>
          <div
            className="search-bar"
            style={{
              backgroundColor: "#FFFFFF",
              borderRadius: "10px",
              padding: "10px",
              marginBottom: "16px",
            }}
          >
            <Input
              placeholder="Search items"
              value={searchQuery}
              className="custom-search-input"
              onChange={(e) => handleSearch(e.target.value)}
              style={{
                width: "100%",
                fontFamily: "Nunito",
                fontWeight: "600",
                borderRadius: "10px",
                padding: "10px",
              }}
            />
          </div>
          {/* Container with fixed height and scrollable overflow */}
          <div style={{ height: "670px", overflowY: "auto", paddingRight: "10px" }} className="custom-scrollbar">
            <Row gutter={16}>
              {displayedItems.map((item) => (
                <Col span={6} key={item.id}>
                  <Card
                    style={{
                      height: "326px",
                      width: "250px",
                      marginBottom: "20px",
                      cursor: "pointer",
                      border: hoveredItemId === item.id ? "3px solid #F2890C" : "2px solid transparent",
                      transition: "border 0.3s, background-color 0.3s",
                      backgroundColor: hoveredItemId === item.id ? "#F2890C1A" : "white",
                      textAlign: "center",
                    }}
                    onClick={() => handleAddToOrder(item.id, item.quantity + 1)}
                    onMouseEnter={() => setHoveredItemId(item.id)}
                    onMouseLeave={() => setHoveredItemId(null)}
                    hoverable
                    className="menu-item-card"
                  >
                    <img
                      src={item.image || Food}
                      alt={item.name}
                      style={{
                        width: "100%",
                        height: "200px",
                        objectFit: "cover",
                      }}
                    />
                    <h4
                      style={{
                        margin: 0,
                        fontFamily: "Nunito",
                        color: hoveredItemId === item.id ? "#F2890C" : "#000",
                        fontWeight: "600",
                      }}
                    >
                      {item.name}
                    </h4>
                    <p
                      style={{
                        color: hoveredItemId === item.id ? "#F2890C" : "#000",
                        fontFamily: "Nunito",
                        fontWeight: "700",
                      }}
                    >
                      {item.price} LKR
                    </p>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        </Col>

        <Col span={8}>
          <div
            style={{
              backgroundColor: "#FFFFFF",
              borderRadius: "10px",
              padding: "10px",
              height: "757px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div>
              <h3 style={{ fontFamily: "Nunito", marginTop: "10px" }}>Please Select The Items:</h3>
              <Divider style={{ marginTop: "10px" }} />
              <Collapse
                accordion
                expandIcon={({ isActive }) => (isActive ? <CaretDownOutlined /> : <CaretRightOutlined />)}
                style={{ border: "none", backgroundColor: "#F5F5F5" }}
              >
                {selectedItems.map((item) => (
                  <Panel
                    style={{
                      border: "none",
                      backgroundColor: "#F5F5F5",
                      borderRadius: "10px",
                      fontFamily: "Nunito",
                    }}
                    header={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Text style={{ fontFamily: "Nunito", fontWeight: "600" }}>
                          {item.name} x {item.quantity}
                        </Text>
                        <CloseCircleOutlined onClick={() => handleRemoveFromOrder(item.id)} />
                      </div>
                    }
                    key={item.id}
                  >
                    <Row gutter={16}>
                      <Col span={12}>
                        <Text style={{ fontFamily: "Nunito", fontWeight: "700" }}> Quantity </Text>
                      </Col>
                      <Col span={12}>
                        <Text style={{ fontFamily: "Nunito", fontWeight: "700" }}>Discount:</Text>
                      </Col>
                      <Col span={12}>
                        <InputNumber
                          min={1}
                          value={item.quantity}
                          onChange={(value) => handleQuantityChange(item.id, value)}
                        />
                      </Col>
                      <Col span={12}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <InputNumber
                            min={0}
                            max={item.discountType === "percentage" ? 100 : undefined} // Cap at 100 for percentage type
                            value={item.discount}
                            onChange={(value) => {
                              // Ensure value doesn't exceed 100% for percentage-based discounts and doesn't go below 0
                              const validatedValue = item.discountType === "percentage" ? Math.min(value, 100) : value;

                              handleDiscountChange(
                                item.id,
                                validatedValue >= 0 ? validatedValue : 0,
                                item.discountType
                              ); // Ensure non-negative value
                            }}
                            style={{ width: "60%" }}
                          />

                          <Select
                            value={item.discountType}
                            onChange={(value) => handleDiscountChange(item.id, item.discount, value)}
                            style={{ width: "35%" }}
                          >
                            <Select.Option value="fixed">LKR</Select.Option>
                            <Select.Option value="percentage">%</Select.Option>
                          </Select>
                        </div>
                      </Col>
                      <Col span={12} style={{ marginTop: "10px" }}>
                        <Text style={{ fontFamily: "Nunito", fontWeight: "700" }}>Order Note</Text>
                      </Col>
                      <Col span={24}>
                        <Input
                          placeholder="Enter order note"
                          value={item.note || ""}
                          onChange={(e) => handleNoteChange(item.id, e.target.value)}
                          style={{ width: "100%", marginBottom: "8px" }}
                        />
                      </Col>
                    </Row>
                  </Panel>
                ))}
              </Collapse>
            </div>
            {selectedItems.length > 0 && (
              <>
                <div>
                  <Divider />
                  <div
                    style={{
                      marginTop: "16px",
                      fontFamily: "Nunito",
                      fontWeight: "600",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "8px",
                      }}
                    >
                      <span>Subtotal:</span>
                      <span>{orderAmount} LKR</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "8px",
                      }}
                    >
                      <span>Service Charge 10%:</span>
                      <span>{(orderAmount * 0.1).toFixed(2)} LKR</span> {/* Assuming 10% tax */}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "8px",
                        fontWeight: "bold",
                        fontSize: "22px",
                      }}
                    >
                      <span>Payable Amount:</span>
                      <span>{(orderAmount + orderAmount * 0.1).toFixed(2)} LKR</span>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "16px",
                    }}
                  >
                    <Button
                      type="primary"
                      onClick={handlePrintKot}
                      style={{
                        backgroundColor: "#F2890C",
                        borderColor: "#F2890C",
                        flex: 1,
                        padding: "10px",
                        marginRight: "15px",
                        fontFamily: "Nunito",
                        fontWeight: "600",
                        height: "50px",
                      }}
                    >
                      <img src={Pause} width={16} height={16} alt="Dashboard Icon" style={{ marginRight: "8px" }} />
                      Kitchen Order Ticket
                    </Button>
                    <Button
                      type="primary"
                      onClick={handleConfirmOrder}
                      style={{
                        backgroundColor: "#09AA29",
                        borderColor: "#09AA29",
                        flex: 1,
                        fontFamily: "Nunito",
                        fontWeight: "600",
                        height: "50px",
                      }}
                    >
                      <img src={Rights} width={16} height={16} alt="Dashboard Icon" style={{ marginRight: "8px" }} />
                      Confirm Order
                    </Button>
                  </div>
                </div>
              </>
            )}
          </div>
        </Col>
      </Row>

      <Modal
        visible={printKotModalVisible}
        footer={null}
        onCancel={() => setPrintKotModalVisible(false)}
        bodyStyle={{ padding: 0 }}
      >
        <div>
          <ReactToPrint
            trigger={() => <Button style={{ margin: "10px" }}>Print this out!</Button>}
            content={() => document.getElementById("kotToPrint")}
            pageStyle={pageStyle}
          />
          <div id="kotToPrint">
            <Kot
              items={kotItems}
              table={selectedTableId}
              order={orderId}
              date={new Date().toLocaleString()}
              isPrint={true}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default OrderManagement;
